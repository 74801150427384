<template>
  <div>
    <b-row class="mt-2 match-height">
      <!-- <b-col xl="4" md="4" sm="6">
        <settings-card icon="SettingsIcon" statistic="General Settings" link="general" statistic-title="General Settings" color="secondary" />
      </b-col> -->
      <b-col xl="4" md="4" sm="6" v-show="$Can('see_permission')">
        <settings-card icon="ShieldIcon" statistic="Roles & Permissions" link="userrole" statistic-title="User Roles" color="success" />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <settings-card icon="FileTextIcon" statistic="Survey Settings" link="surveys" statistic-title="Survey Settings" color="secondary" />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <settings-card icon="ClipboardIcon" statistic="Grade Level" link="grade-levels" statistic-title="Grade Level" color="warning" />
      </b-col>

      <b-col xl="4" md="4" sm="6" v-show="$Can('summary_access')">
        <settings-card icon="ColumnsIcon" statistic="Summary  Explanation" link="summary" statistic-title="Summary Explanation" color="info" />
      </b-col>

      <b-col xl="4" md="4" sm="6"  v-show="$Can('resource_add')">
        <settings-card icon="FolderIcon" statistic="Upload Resource Document" link="resources" statistic-title="Upload Resource Documen" color="success" />
      </b-col>

      <b-col xl="4" md="4" sm="6" v-show="$Can('support_add')">
        <settings-card icon="HelpCircleIcon" statistic="Upload Support Document" link="support" statistic-title="Upload Support Document" color="success" />
      </b-col>
      <!-- <b-col xl="4" md="4" sm="6">
        <settings-card icon="GridIcon" statistic="Groups" link="groups" statistic-title="Groups" color="secondary" />
      </b-col> -->
      <!-- <b-col xl="4" md="4" sm="6">
        <settings-card icon="MessageSquareIcon" statistic="Questions" link="questions" statistic-title="Quesitons" color="primary" />
      </b-col> -->
      <!-- <b-col xl="4" md="4" sm="6">
        <settings-card icon="UserPlusIcon" statistic="Add Survey" link="addSurvey" statistic-title="Add Survey" color="warning" />
      </b-col> -->

      <!-- <b-col xl="4" md="4" sm="6">
        <settings-card icon="ClipboardIcon" statistic="Growth Profile" link="growthProfile" statistic-title="Growth Profile" color="warning" />
      </b-col> -->
      <!-- <b-col xl="4" md="4" sm="6">
        <settings-card icon="SlackIcon" statistic="Dashboard" link="dashboard" statistic-title="Dashboard" color="success" />
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination } from 'bootstrap-vue';

import useUserList from './useSettings';

import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from './settingsStoreModule';
import SettingsCard from '@core/components/statistics-cards/SettingsCard.vue';
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,

    SettingsCard,
  },

  methods: {},

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const { loading } = useUserList();

    return {
      loading,
      // Filter
    };
  },
  mounted() {},
  created() {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
  },
};
</script>
